<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
    class="mt-5"
  >
    <v-row
      justify="center"
      :class="isXs?'titleSearch':''"
    >
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-account-outline"
          inline
          title="修改密码："
          class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
        >
          <!-- <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              修改密码：
            </div>
          </template> -->
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="oldPw"
                    label="原密码"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="newPw"
                    class="purple-input"
                    label="新密码"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="newPwCode"
                    class="purple-input"
                    label="重复新密码"
                    :type="show3 ? 'text' : 'password'"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show3 = !show3"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="uploadPassWord"
                  >
                    修改
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-overlay
      :value="PwSuccess"
    >
      <v-card
        light
      >
        <v-card-text
          class="text-center"
        >
          <v-icon
            x-large
            medium
            color="error"
          >
            fa fa-exclamation-triangle
          </v-icon><br>
          <h3
            color="error"
            class="pt-5"
          >
            密码修改成功。
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            @click="PwSuccess = false"
          >
            知道了
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-overlay
      :value="newPwCodeSuccess"
    >
      <v-card
        light
      >
        <v-card-text
          class="text-center"
        >
          <v-icon
            x-large
            medium
            color="error"
          >
            fa fa-exclamation-triangle
          </v-icon><br>
          <h3
            color="error"
            class="pt-5"
          >
            两次输入的新密码不一致。
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            @click="newPwCodeSuccess = false"
          >
            知道了
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-overlay
      :value="PwError"
    >
      <v-card
        light
      >
        <v-card-text
          class="text-center"
        >
          <v-icon
            x-large
            medium
            color="error"
          >
            fa fa-exclamation-triangle
          </v-icon><br>
          <h3
            color="error"
            class="pt-5"
          >
            密码修改失败。
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            @click="PwError = false"
          >
            知道了
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        oldPw: '',
        newPw: '',
        newPwCode: '',
        show1: false,
        show2: false,
        show3: false,
        overlay: false,
        PwSuccess: false,
        PwError: false,
        newPwCodeSuccess: false,
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    methods: {
      uploadPassWord () {
        if (this.newPw !== this.newPwCode) {
          this.$swal({
            title: '两次输入的新密码不一样!',
            icon: 'error',
            confirmButtonText: '确定',
          })
        } else {
          this.overlay = true
          this.$axios.put('/password', {
            oldPassword: this.oldPw,
            password: this.newPw,
          }).then(res => {
            this.overlay = false
            this.$swal({
              title: '密码修改成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            localStorage.clear()
            this.$route.push('/login')
          }).catch(err => {
            // console.log(err.response.data.code)
            if (err.response.data.code === 422) {
              this.$swal({
                title: err.response.data.msg,
                icon: 'error',
                confirmButtonText: '确定',
              })
            }
            this.overlay = false
            // this.PwError = true
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .titleSearch .v-text-field__details{
display:none
}
</style>
